import * as React from 'react'

import { Link, Stack, Typography } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'

import BookButton from '../components/book_button'
import CursiveTypography from '../components/cursive_typography'
import NavBar from '../components/nav_bar'
import { Parallax, ParallaxGroup, ParallaxLayer } from '../components/parallax'
import SeoHeaders from '../components/seo_headers'
import SocialButtons from '../components/social_buttons'
import LandingFooter from '../sections/landing/footer'

const ReservationMailLink = () => (
  <Link
    target='_blank'
    rel='noopener noreferrer'
    href='mailto:reservas@oceanpacifics.cl'
    sx={(theme) => ({
      fontWeight: 500,
      color: theme.palette.common.white,
      textDecorationColor: theme.palette.common.white,
    })}
  >
    reservas@oceanpacifics.cl
  </Link>
)

const BusinessesPage = () => {
  return (
    <React.Fragment>
      <SeoHeaders
        title='Reservas para empresas'
        description={(
          'Reserve una comida para su empresa, reunión de negocios, celebración, u otros.'
          + ' Nuestra oferta contiene menús preparados y experiencias a la medida.'
        )}
      />
      <NavBar />
      <SocialButtons />
      <BookButton />
      <Parallax>
        <ParallaxGroup>
          <ParallaxLayer depth={-1}>
            <StaticImage
              src='../images/background-empresas.jpeg'
              alt='Salón de telecomunicaciones'
              layout='fullWidth'
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              }}
              quality={90}
            />
          </ParallaxLayer>
          <ParallaxLayer
            bgcolor='rgba(28, 35, 53, 0.85)'
            color='background.default'
          >
            <Stack
              py={6}
              px={3}
              spacing={4}
              alignItems='center'
              justifyContent='center'
              height='100%'
              maxWidth='md'
              mx='auto'
              my='auto'
            >
              <CursiveTypography variant='h1'>
                Reservas para Empresas
              </CursiveTypography>
              <Typography
                textAlign='justify'
              >
                Sabemos lo importante que es para usted su evento, es por esto que le
                agradecemos de antemano que nos considere para ser parte de este
                desafío. Si necesita un menú para su empresa, ya sea para reuniones de
                negocios, celebraciones, despedidas, entre otros, envíenos un correo
                a <ReservationMailLink /> donde lo dejaremos en contacto con uno de
                nuestros capitanes de servicio, quiénes entregarán soluciones a todas
                sus necesidades.
              </Typography>
              <Typography
                textAlign='justify'
              >
                Si bien tenemos menús establecidos, nuestro equipo de cocina está
                preparado para ajustarse a cualquier requerimiento que desee.
              </Typography>
            </Stack>
          </ParallaxLayer>
        </ParallaxGroup>
        <LandingFooter />
      </Parallax>
    </React.Fragment>
  )
}

export default BusinessesPage
